<template>
  <div></div>
  <div class="container-fluid footer-align">
    <!--    For Desktop-->
    <div
      class="d-lg-flex d-none shadow-sm bg-white row py-4 mb-5 border-bottom custom-sticky-top"
    >
      <!--                Step-1-->
      <div class="col d-flex d-none justify-content-center align-items-center">
        <!--                  Step Number-->
        <div
          :class="`mr-3 badge badge-${
            $route.name != 'register' ? 'info' : 'primary'
          } px-3 py-2 shadow-sm align-self-center`"
          style="font-size: 150%; border-radius: 10px"
        >
          <template v-if="$route.name != 'register'">
            <font-awesome-icon icon="check-circle" />
          </template>
          <template v-else> 1 </template>
        </div>
        <!--                  Step Number Text-->
        <div class="text-left">
          <span class="h4">Step 1</span>

          <!--                    Step Details-->
          <div class="text-muted">Company Details</div>
        </div>
      </div>

      <!--                Step-2-->
      <div class="col d-flex justify-content-center align-items-center">
        <!--                  Step Number-->
        <div
          :class="`mr-3  badge badge-${
            $route.name == 'registerOrgAdmin'
              ? 'primary'
              : $route.name == 'register'
              ? 'secondary'
              : 'info'
          } px-3 py-2 shadow-sm align-self-center`"
          style="font-size: 150%; border-radius: 10px"
        >
          <template
            v-if="
              $route.name != 'registerOrgAdmin' && $route.name != 'register'
            "
          >
            <font-awesome-icon icon="check-circle" />
          </template>
          <template v-else> 2 </template>
        </div>
        <!--                  Step Number Text-->
        <div class="text-left">
          <span class="h4">Step 2</span>

          <!--                    Step Details-->
          <div class="text-muted">Personal Details</div>
        </div>
      </div>

      <!--                Step-3-->
      <div class="col d-flex justify-content-center align-items-center">
        <!--                  Step Number-->
        <div
          :class="`mr-3  badge badge-${
            $route.name == 'continueEmail'
              ? 'primary'
              : $route.name == 'register' || $route.name == 'registerOrgAdmin'
              ? 'secondary'
              : 'info'
          } px-3 py-2 shadow-sm align-self-center`"
          style="font-size: 150%; border-radius: 10px"
        >
          <template
            v-if="
              $route.name != 'continueEmail' &&
              $route.name != 'register' &&
              $route.name != 'registerOrgAdmin'
            "
          >
            <font-awesome-icon icon="check-circle" />
          </template>
          <template v-else> 3 </template>
        </div>
        <!--                  Step Number Text-->
        <div class="text-left">
          <span class="h4">Step 3</span>

          <!--                    Step Details-->
          <div class="text-muted">Confirm Email</div>
        </div>
      </div>

      <!--                Step-4-->
      <div class="col d-flex justify-content-center align-items-center">
        <!--                  Step Number-->
        <div
          :class="`mr-3  badge badge-${
            $route.name == 'userRegistration'
              ? 'primary'
              : $route.name == 'register' ||
                $route.name == 'registerOrgAdmin' ||
                $route.name == 'continueEmail'
              ? 'secondary'
              : 'info'
          } px-3 py-2 shadow-sm align-self-center`"
          style="font-size: 150%; border-radius: 10px"
        >
          <template v-if="$route.name == 'success'">
            <font-awesome-icon icon="check-circle" />
          </template>
          <template v-else> 4 </template>
        </div>
        <!--                  Step Number Text-->
        <div class="text-left">
          <span class="h4">Step 4</span>

          <!--                    Step Details-->
          <div class="text-muted">Complete Registration</div>
        </div>
      </div>

      <!--                Step-5-->
      <div class="col d-flex justify-content-center align-items-center">
        <!--                  Step Number-->
        <div
          :class="`mr-3 badge badge-${
            $route.name == 'success' ? 'success' : 'secondary'
          } px-3 py-2 shadow-sm align-self-center`"
          style="font-size: 150%; border-radius: 10px"
        >
          <font-awesome-icon icon="check-circle" />
        </div>
        <!--                  Step Number Text-->
        <div class="text-left">
          <span class="h4">Done!</span>

          <!--                    Step Details-->
          <div class="text-muted">Get Started</div>
        </div>
      </div>
    </div>

    <router-view class="custom-container d-lg-block d-none"></router-view>
    <!--    End: Desktop-->

    <!--    For Mobile Device-->
    <div class="d-flex d-lg-none align-items-center">
      <div class="w-100">
        <router-view></router-view>
      </div>
    </div>
    <!--    End: Mobile-->
  </div>

  <!--  footer-->
  <footer-component></footer-component>
</template>

<script src="./account-layout.ts"></script>
<style></style>
