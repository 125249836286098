import { Options, Vue } from "vue-class-component";
import FooterComponent from "@/components/FooterComponent.vue";

@Options({
  components: { FooterComponent },
  data: function () {
    return {};
  },
})
export default class AccountLayoutComponent extends Vue {}
