<template>
  <footer class="section-footer mt-3">
    <div class="container">
      <div class="row justify-content-between align-items-center text-white">
        <div
          class="col-sm-12 col-md-6 mb-2 mb-md-0 col-lg-3 text-lg-left text-md-center text-center"
        >
          <a
            target="_blank"
            href="https://www.redcastletechnology.com/"
            class="footer-logo d-inline-block"
            ><img src="@/assets/logo-white.svg" alt="logo"
          /></a>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 text-center">
          <span>Registered in Ireland: 693781</span>
        </div>
        <div class="col-md-12 col-lg-4 mt-lg-0 mt-3">
          <div class="footer-links text-center text-lg-right">
            <p class="mb-0 mb-lg-2">
              <a href="#" @click.prevent class="text-decoration-none text-white"
                >Terms of Use</a
              >
              |
              <a
                target="_blank"
                class="text-decoration-none text-white"
                href="https://www.redcastletechnology.com/privacy.html"
                >Privacy Policy</a
              >
            </p>
            <p>
              ©{{ new Date().getFullYear() }} Red Castle Technology. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped></style>
